import Image from "next/image"
import { FunctionComponent, useEffect } from "react"
import Select, { components } from "react-select"

interface OptionType {
  label: string
  value: string
  icon: string
}

interface DropdownProps {
  meta?: any
  label?: string
  placeholder?: string
  options?: {
    label: string
    value: string
    icon: string
  }[]
  isSearchable?: boolean
  rest?: any
  input?: any
  isRequired?: boolean
  disabled?: boolean
  helperText?: string
  isMulti?: boolean
  onChange?: any
  isOpenTop?: boolean
  isNewPaymentview?: boolean
  isWCForm?: boolean
}

const Dropdown: FunctionComponent<DropdownProps> = ({
  input,
  meta,
  label,
  placeholder,
  options,
  isSearchable = false,
  isRequired,
  disabled,
  helperText,
  isMulti,
  onChange,
  isOpenTop,
  isNewPaymentview,
  isWCForm,
}) => {
  const { Option } = components

  useEffect(() => {
    if (document)
      document.getElementById(input.name)?.setAttribute("name", input.name)
  }, [])

  function IconOption(props: any) {
    const {
      data: { label, icon },
    } = props

    return (
      <Option {...props}>
        <div className="flex items-center gap-2  -z-2">
          {icon && (
            <div>
              <Image src={icon} alt="" width={21} height={14} />
            </div>
          )}
          <span className="  ml-[13px]">{label}</span>
        </div>
      </Option>
    )
  }

  const ValueContainesr = ({ children, ...rest }: any) => {
    const { icon } = rest?.data || { label: "", icon: "" }

    return (
      <div className="flex items-center">
        {icon && (
          <div className=" mr-1">
            <Image src={icon || ""} width={21} height={14} />
          </div>
        )}

        <span className=" text-[#0A0926] font-bold text-base">{children}</span>
      </div>
    )
  }
  return (
    <div className="relative w-full">
      <div className={input.value !== "" ? "is-filled" : ""}>
        {label && !isNewPaymentview && (
          <label
            className="absolute text-xxs inline-block opacity-0 text-black px-4 top-0 cursor-auto mt-1"
            htmlFor={`field-${input.name}`}
          >
            {label}
          </label>
        )}
        <Select
          id={`field-${input.name}`}
          inputId={input.name}
          className={` border mx-auto  ${
            isNewPaymentview
              ? " border-none w-full md:w-[90%]"
              : "border-gray-850 w-full"
          } rounded-6 relative ${
            meta.error && meta.touched && " border-red-600 bg-red-100"
          }`}
          theme={(theme) => ({
            ...theme,
            borderRadius: 6,
            // boxShadow: 'none',
            colors: {
              ...theme.colors,
              primary: "#443eff",
            },
          })}
          styles={{
            control: (styles, state: any) => {
              return {
                ...styles,
                borderWidth: 0,
                background: isNewPaymentview ? "transparent" : "white",
                paddingTop: isNewPaymentview ? 0 : "5px",
                paddingBottom: isNewPaymentview ? 0 : "5px",
                paddingLeft: isNewPaymentview ? "10px" : "1rem",
                boxShadow: isNewPaymentview
                  ? "none"
                  : !state.isFocused
                  ? "0px 0px 0px 1px #bdbdbd"
                  : "0px 0px 0px 1px #443eff",
                paddingRight: isNewPaymentview ? "4px" : "0.4rem",
                border: state.isFocused && "0px",
                outline: "none",
                borderColor: "#bdbdbd",
              }
            },
            menuList: (styles) => {
              return {
                ...styles,
                maxHeight: "200px",
              }
            },
            menu: (styles) => {
              return {
                ...styles,
                zIndex: 99999,
                top: 50,
                width: isNewPaymentview ? 166 : "auto",
                overflowY: "auto",
                left: isNewPaymentview ? -16 : 0,
                right: isNewPaymentview ? 16 : 0,
                ...(isOpenTop && { top: "auto", bottom: "100%" }),
              }
            },
            input: (styles) => {
              return {
                ...styles,
                transform: "translate3d(0px,9px,0)",
                height: "auto",
                textShadow: "0 0 0 #2196f3",
              }
            },
            placeholder: (styles) => {
              return {
                ...styles,
                color: "#616161",
              }
            },
            singleValue: (styles) => {
              return {
                ...styles,
                paddingTop: isNewPaymentview ? "0px" : "14px",
                marginLeft: "0px",
                color: isWCForm ? "#1C1C1C" : "#616161",
                top: `${label ? "50%" : "37%"}`,
                fontSize: 14,
              }
            },
            multiValue: (styles) => {
              return {
                ...styles,
                marginTop: "18px",
                marginRight: "20px",
                color: "white !important",
                backgroundColor: "#5A304F",
                cursor: "pointer",
              }
            },
            multiValueLabel: (styles) => ({
              ...styles,
              color: "white",
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              padding: isNewPaymentview ? "0 5px 0 0" : "8px 0px",

              color: "#686868",
            }),
            indicatorSeparator: (styles) => ({
              ...styles,
              display: "none",
            }),
            option: (styles) => ({
              ...styles,
              borderBottom: "0.5px solid #D3D3D3",
            }),
            valueContainer: (styles) => {
              return {
                ...styles,
                padding: isNewPaymentview ? 0 : "4px 0px",
              }
            },
          }}
          placeholder={placeholder}
          options={options}
          components={
            isNewPaymentview
              ? { Option: IconOption, SingleValue: ValueContainesr }
              : {}
          }
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onChange={(v) => {
            if (isMulti) {
              if (v) {
                const valueArr = (v as OptionType[]).reduce(
                  (a: string[], c: OptionType) => {
                    a.push(c.value)
                    return a
                  },
                  [],
                )
                input.onChange(valueArr)
              } else {
                input.onChange(null)
              }
            } else {
              input.onChange((v as OptionType).value)
              if (onChange) {
                onChange(v as OptionType)
              }
            }
          }}
          required={isRequired}
          openOuterUp={true}
          isSearchable={isSearchable}
          value={options?.filter((a) =>
            isMulti ? input.value.includes(a.value) : a.value == input.value,
          )}
          defaultValue={options?.find((a) => a.value == input.value)}
          isDisabled={disabled}
          isMulti={isMulti}
        />
      </div>
      {meta.touched && meta.error && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      {helperText && (
        <p className="mt-2 text-sm text-lightgray-400 leading-tight pl-4">
          {helperText}
        </p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
          border-color: #bdbdbd;
        }
        .is-filled input {
          padding-top: 1.25rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          z-index: 10;
          pointer-events: none;
        }
        .css-kzbgha-menu,
        .css-1d96cs0-menu {
          top: auto;
          bottom: 100%;
        }
      `}</style>
    </div>
  )
}

export default Dropdown
